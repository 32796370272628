import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, CardContent, Stack } from '@mui/material';
import { PageEntityConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
// eslint-disable-next-line import/no-cycle
import {
    CampaignBookingSummaryDialog,
    CampaignOverviewDetails,
    CampaignOverviewDetailTabs,
    CampaignThumbnail,
} from 'c-main/Components';
import { useEntityData } from 'c-data';
import { campaignIcon } from 'c-main/icons';
import { Button, LinkButton, TransparentCard } from 'c-components';
import { Campaign, CampaignStatus, PermissionName, UserType } from '@uniled/api-sdk';
import { campaignName, isCampaignArchived } from 'c-main/Lib/Campaign';
import { useAPIClientRequest, useEntityPage } from 'c-hooks';
import { useCommonTranslation } from 'c-translation';
import { useBoolean } from 'react-hanger';
import { generatePath, useHistory } from 'react-router-dom';
import { CardControl } from 'c-types';
import apiClient from 'c-data/apiClient';
import { IfHasAllPermissions, IfUserIsOneOfTypes } from 'c-auth-module/Components';
import { Download } from '@mui/icons-material';
import to from 'await-to-js';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { PageStatusSettings, SystemSearchEntityResource } from 'c-system-search';
import { useAtom } from 'jotai';
import useCampaignCreativesData from 'c-main/Components/CreativeManagement/useCampaignCreativesData';
import UploadDialog from 'c-main/Components/CreativeManagement/Components/Creatives/UploadDialog';
import { PcaDialog } from 'c-main/Components/Campaign/PcaDialog/PcaDialog';
import ReactGA from 'react-ga';
import { isObjectEmpty } from 'c-main/Pages/Campaign/campaignUtils';
import PptPCADialog from 'c-main/Components/Campaign/PptPca/PptPCADialog';
import AiCampaignChat from '../AiCampaignChat/AiCampaignChat';
import CampaignName from '../../../Components/Campaign/CampaignName';
import LineItemEditSetup from '../UpdateCampaignLineItemsPage';
import NewCampaignSetup from '../NewCampaignPage';
import CampaignOverviewEdit from './CampaignOverviewEdit';
import CampaignShareDialog from './CampaignShareDialog';
import CampaignInstructions from './CampaignInstructionsDialog';
import { atom_campaignUploadArtworkOpen } from './atoms';

const CreateCreativesPerms: PermissionName[] = [PermissionName.UniledportalCreativeCreate];
const UpdateDisplaysPermissions: PermissionName[] = LineItemEditSetup.systemSearch.permissions;
const NewCampaignPermissions: PermissionName[] = NewCampaignSetup.systemSearch.permissions;
const ShareCampaignUserTypes = [UserType.Buyer, UserType.Admin];
const CampaignUpdateDialogPermissions = [PermissionName.UniledportalCampaignUpdate];
const BookingSummaryPermissions = [PermissionName.UniledportalCampaign_booking_summariesRead];
// const CampaignValidateDialogPermissions = [
//     PermissionName.UniledportalCampaignUpdate,
//     PermissionName.DisplaysLine_itemsRead,
//     PermissionName.UniledportalCreativeRead,
//     PermissionName.UniledportalFileRead,
//     PermissionName.UniledportalResolutionRead,
// ];
const SpecsDownloadPermissions = [PermissionName.UniledportalCampaignSpecsDownload];
const CreativeSpecsDownloadPermissions = [PermissionName.UniledportalCampaignCreativeDownload];
const CreativeSpecsDownloadUserTypes = [UserType.Admin, UserType.Agency, UserType.Buyer];

const cardHeaderProps = { sx: { pb: 0 } };
const CampaignOverviewPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const { id } = useEntityPage();
    const { getById, upsertEntity } = useEntityData<Campaign>('Campaign');
    const campaign = getById({ id });
    const editCampaignDialogState = useBoolean(false);
    const campaignShareDialogState = useBoolean(false);
    const campaignBookingSummaryState = useBoolean(false);
    const pcaState = useBoolean(false);
    const pptPcaState = useBoolean(false);
    const editLink = useMemo(
        () => generatePath(PostAuthRoutes.Campaigns.UpdateCampaignLineItems, { id }),
        [id],
    );
    const cloneLink = useMemo(
        () => generatePath(PostAuthRoutes.Campaigns.CloneCampaign, { id }),
        [id],
    );

    const {
        start: StartArchive,
        isLoading: IsArchiving,
        hasFailed: HasFailedArchiving,
        error: ArchivingError,
    } = useAPIClientRequest(apiClient.Entities.Campaign.archive);

    // const {
    //     start: StartValidating,
    //     isLoading: IsValidating,
    //     hasFailed: HasFailedValidating,
    //     error: ValidatingError,
    // } = useAPIClientRequest(apiClient.Entities.Campaign.validate);

    const isCampaignArchived = useMemo(
        () => campaign?.status === CampaignStatus.CampaignArchived ?? false,
        [campaign?.status],
    );

    const performArchive = useCallback(async () => {
        if (!IsArchiving) {
            const [err, response] = await to(StartArchive(id, !isCampaignArchived));

            if (!err && response?.data?.data?.id === id) {
                upsertEntity(response.data.data as unknown as Campaign);
            }
        }
    }, [IsArchiving, StartArchive, id, isCampaignArchived, upsertEntity]);

    // const performValidation = useCallback(async () => {
    //     if (!IsValidating) {
    //         const [err, response] = await to(StartValidating(id, ['errors']));
    //
    //         if (!err && response?.data?.data?.id === id) {
    //             upsertEntity(response.data.data as unknown as Campaign);
    //         }
    //     }
    // }, [IsValidating, StartValidating, id, upsertEntity]);

    const t = useCommonTranslation();
    const history = useHistory();

    const campaignScheduleClick = useCallback(() => {
        const pathnameWithoutQuery = history.location.pathname;
        window.open(`${pathnameWithoutQuery}/schedule`, '_blank');
    }, [history]);

    const newControls = useMemo<CardControl[]>(
        () => [
            {
                label: t('Modules.Main.Campaigns.Overview.ActionLabels.edit'),
                permissions: CampaignUpdateDialogPermissions,
                onClick: editCampaignDialogState.setTrue,
            },
            {
                label: t('Modules.Main.Campaigns.EditLineItems.editLink'),
                path: editLink,
                permissions: UpdateDisplaysPermissions,
            },
            {
                label: t('Modules.Main.Campaigns.EditLineItems.cloneCampaign'),
                path: cloneLink,
                permissions: NewCampaignPermissions,
            },
            {
                label: t('Modules.Main.Campaigns.Overview.ActionLabels.share'),
                userTypes: ShareCampaignUserTypes,
                onClick: campaignShareDialogState.setTrue,
            },
            {
                label: t('Modules.Main.Campaigns.Overview.ActionLabels.bookingSummary'),
                permissions: BookingSummaryPermissions,
                onClick: campaignBookingSummaryState.setTrue,
            },
            {
                label: t('Modules.Main.Campaigns.Overview.ActionLabels.campaignSchedule'),
                permissions: BookingSummaryPermissions,
                onClick: campaignScheduleClick,
            },
            {
                label: t('Modules.Main.Campaigns.Overview.ActionLabels.cad'),
                permissions: [PermissionName.Admin],
                onClick: pcaState.setTrue,
            },
            {
                label: t('Modules.Main.Campaigns.Overview.ActionLabels.pca'),
                permissions: [PermissionName.Admin],
                onClick: pptPcaState.setTrue,
            },
            {
                label: (
                    <>
                        {isCampaignArchived
                            ? t('Modules.Main.Campaigns.Overview.ActionLabels.unarchive')
                            : t('Modules.Main.Campaigns.Overview.ActionLabels.archive')}
                    </>
                ),
                preventCloseOnClick: true,
                permissions: CampaignUpdateDialogPermissions,
                onClick: performArchive,
                disabled: IsArchiving,
                isLoading: IsArchiving,
                error: HasFailedArchiving ? ArchivingError : undefined,
            },
            // {
            //     label: t('Modules.Main.Campaigns.Overview.ActionLabels.validateCreatives'),
            //     preventCloseOnClick: true,
            //     permissions: CampaignValidateDialogPermissions,
            //     onClick: performValidation,
            //     disabled: IsValidating,
            //     isLoading: IsValidating,
            //     error: HasFailedValidating ? ValidatingError : undefined,
            // },
        ],
        [
            t,
            editCampaignDialogState.setTrue,
            editLink,
            cloneLink,
            campaignShareDialogState.setTrue,
            campaignBookingSummaryState.setTrue,
            campaignScheduleClick,
            pcaState.setTrue,
            pptPcaState.setTrue,
            isCampaignArchived,
            performArchive,
            IsArchiving,
            HasFailedArchiving,
            ArchivingError,
        ],
    );

    const details = useMemo(
        () => (
            <TransparentCard
                title={<CampaignName campaignId={id} />}
                dropdownControls={newControls}
                cardHeaderProps={cardHeaderProps}
            >
                <CardContent>
                    <CampaignOverviewDetails id={id} />
                </CardContent>
            </TransparentCard>
        ),
        [id, newControls],
    );

    return (
        <>
            <CampaignShareDialog
                campaignId={id}
                open={campaignShareDialogState.value}
                onClose={campaignShareDialogState.setFalse}
            />
            <CampaignBookingSummaryDialog
                campaign={campaign}
                open={campaignBookingSummaryState.value}
                onClose={campaignBookingSummaryState.setFalse}
            />
            <PcaDialog id={campaign.id} pcaState={pcaState} campaign={campaign} />
            <PptPCADialog id={campaign.id} pcaState={pptPcaState} />
            <CampaignOverviewEdit id={id} dialogState={editCampaignDialogState} />
            <Box display="flex">
                <Box pr={2}>
                    <CampaignThumbnail campaign={campaign} size={80} preferredSize="medium" />
                </Box>
                {details}
            </Box>
            <CampaignOverviewDetailTabs id={id} />
        </>
    );
};

const PageBreadcrumbs: SystemSearchEntityResource<Campaign>['renderEntityBreadcrumbs'] = ({
    defaultContent,
    entity,
}) => {
    const noPcaData = isObjectEmpty(entity?.pca_available);
    const t = useCommonTranslation();
    const specDownloadBtn = useMemo(
        () => (
            <>
                <IfUserIsOneOfTypes roles={CreativeSpecsDownloadUserTypes}>
                    <IfHasAllPermissions permissions={SpecsDownloadPermissions}>
                        <LinkButton
                            id="campaign-header-download-spec"
                            variant="outlined"
                            sx={{ ml: 'auto' }}
                            to={apiClient.Entities.Campaign.downloadLink(entity.id, {
                                include_display_specs: true,
                            })}
                            target="_blank"
                        >
                            <Download fontSize="inherit" />{' '}
                            {t('Modules.Main.Campaigns.Overview.ActionLabels.downloadSpec')}
                        </LinkButton>
                    </IfHasAllPermissions>
                </IfUserIsOneOfTypes>
            </>
        ),
        [entity.id, t],
    );

    const creativesDownloadBtn = useMemo(
        () => (
            <>
                <IfHasAllPermissions permissions={CreativeSpecsDownloadPermissions}>
                    <LinkButton
                        id="campaign-header-download-creatives"
                        variant="outlined"
                        sx={{ ml: 'auto' }}
                        to={apiClient.Entities.Campaign.downloadLink(entity.id, {
                            include_creatives: true,
                        })}
                        target="_blank"
                    >
                        <Download fontSize="inherit" />{' '}
                        {t('Modules.Main.Campaigns.Overview.ActionLabels.downloadCreatives')}
                    </LinkButton>
                </IfHasAllPermissions>
            </>
        ),
        [entity.id, t],
    );
    const [artworkDialogOpen, setArtworkDialogOpen] = useAtom(atom_campaignUploadArtworkOpen);

    const openUploadDialog = useCallback(() => {
        setArtworkDialogOpen(true);
    }, [setArtworkDialogOpen]);
    const closeUploadDialog = useCallback(() => {
        setArtworkDialogOpen(false);
    }, [setArtworkDialogOpen]);
    const { updateCreatives, reValidateCampaign, existingFilesArray } = useCampaignCreativesData(
        entity.id,
    );

    const uploadArtworkBtn = useMemo(
        () => (
            <IfHasAllPermissions permissions={CreateCreativesPerms}>
                <Button onClick={openUploadDialog} id="campaign-header-upload-artwork">
                    {t('Modules.Main.Campaigns.Overview.ActionLabels.uploadArtwork')}
                </Button>
            </IfHasAllPermissions>
        ),
        [t, openUploadDialog],
    );

    return (
        <Box display="flex">
            {defaultContent}
            <Box ml="auto">
                <Stack gap={1} direction="row" id="campaign-header-buttons">
                    {uploadArtworkBtn}
                    {specDownloadBtn}
                    {creativesDownloadBtn}
                    <CampaignInstructions campaignId={entity.id} />

                    <DndProvider backend={HTML5Backend}>
                        <UploadDialog
                            isDialogOpen={artworkDialogOpen}
                            closeDialog={closeUploadDialog}
                            updateCreatives={updateCreatives}
                            reValidateCampaign={reValidateCampaign}
                            existingFilesArray={existingFilesArray}
                        />
                    </DndProvider>
                    {noPcaData === false && <AiCampaignChat campaignId={entity.id} />}
                </Stack>
            </Box>
        </Box>
    );
};

const setup: PageEntityConfig<Campaign> = {
    id: 'CampaignOverviewPage',
    component: CampaignOverviewPage,
    systemSearch: {
        icon: campaignIcon,
        saveAsRecentPage: true,
        title: undefined,
        description: 'Modules.Main.Campaigns.Overview.description',
        type: 'entity',
        entityName: 'Campaign',
        defaultIncludes: [
            'agency',
            'market',
            'creatives',
            'client',
            'creatives.resolution',
            'creatives.file',
            'date',
            'budgetSummary',
            'budgetSummary.lineItems',
            'budgetSummaries',
            'budgetSummaries.lineItems',
            'notices',
            'errors',
            'creativeThumbnails',
            'bookingSummaries',
            'popShotsCount',
            'missingDisplaysCount',
            'additionalContacts',
            // 'budgetSummaries.lineItems.lineItem',
        ],
        prefix: 'SystemSearch.prefixes.campaign',
        prefixDescription: 'SystemSearch.prefixDescriptions.campaign',
        nameGen: (entity, id) => (entity ? campaignName(entity) : String(id)),
        pageStatus: (entity, id, t) => {
            if (isCampaignArchived(entity)) {
                return {
                    status: t('Modules.Main.Campaigns.Overview.campaignArchivedPageStatus'),
                    statusBoxProps: {
                        bgcolor: 'grey.500',
                        color: 'white',
                        textAlign: 'center',
                        py: 1,
                    },
                } as PageStatusSettings;
            }
            return null;
        },
        searchColumn: ['name', 'reference'],
        alwaysSearchForEntity: true,
        // aliases: [],
        route: PostAuthRoutes.Campaigns.CampaignOverview,
        permissions: [PermissionName.UniledportalCampaignRead],
        breadcrumbs: [
            {
                label: 'Modules.Main.Campaigns.List.metaTitle',
                link: PostAuthRoutes.Campaigns.CampaignList,
            },
        ],
        renderEntityBreadcrumbs: PageBreadcrumbs,
    },
};

export default setup;
