import React, { useCallback, useEffect, useMemo } from 'react';
import FormWrapper from 'c-components/Forms/FormWrapper';
import { useAPIClientRequest, useAutoResetBoolean, useEntityPage } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import {
    Campaign,
    Displays_AudienceVersion,
    SaveCampaignPopSettingsPayload,
} from '@uniled/api-sdk';
import { Controller, useFormContext } from 'react-hook-form';
import {
    Alert,
    AllEntitiesDropdown,
    AutoGrid,
    Button,
    DateField,
    SelectField,
    TextField,
} from 'c-components';
import { Translate, useCommonTranslation } from 'c-translation';
import Checkbox from 'c-components/Forms/Checkbox';
import { Stack } from '@mui/material';
import { NetworkRequestState } from '@uniled/data-layer';
import to from 'await-to-js';
import { useEntityData } from 'c-data';
import { formatDateToMonthYear } from 'c-lib';
import { uniqueCreativeValues } from './utils';
import PopSettingsCreativesSearch from './PopSettingsCreativesSearch';
import PopSettingsLogSourceIdentifier from './PopSettingsLogSourceIdentifier';

type Props = {
    campaignId: number;
};

type FormDataStructure = Partial<
    Omit<SaveCampaignPopSettingsPayload, 'creatives'> & { creatives: string }
>;

export const updateSelectedCreatives = (currentValue: string, updatedValues: string[]) => {
    const currentNames = uniqueCreativeValues(currentValue);
    const updatedNamesSet = new Set(updatedValues);

    // Retain all current names that are still present in the updated values.
    const retainedNames = currentNames.filter(name => updatedNamesSet.has(name));

    // Combine retained names with any new names from the updated values.
    const finalNames = [...new Set([...retainedNames, ...updatedValues])];

    return finalNames.join('\n');
};

const PopSettingsForm: React.FC = () => {
    const t = useCommonTranslation();
    const { watch, setValue } = useFormContext();
    const audienceVersionId = watch('audience_version_id');
    useEffect(() => {
        if (audienceVersionId === undefined) {
            setValue('audience_version_id', null);
        }
    }, [audienceVersionId, setValue]);
    const additionalOption = useMemo(
        () => [
            {
                label: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.clear'),
                value: null,
            },
        ],
        [t],
    );
    // get value of field for creatives here
    return (
        <AutoGrid xs={12} spacing={2} pb={2}>
            <Controller
                name="creatives"
                render={({ field }) => (
                    <Stack gap={2}>
                        <PopSettingsCreativesSearch
                            onSelected={names =>
                                field.onChange(updateSelectedCreatives(field.value, names))
                            }
                            initialSelectedNamesString={field.value}
                        />
                        <TextField
                            {...field}
                            multiline
                            rows={5}
                            label={t(
                                'Modules.Main.Campaigns.CampaignSchedule.popSettings.creativesLabel',
                            )}
                            inputProps={{ sx: { resize: 'vertical' } }}
                            helperText={t(
                                'Modules.Main.Campaigns.CampaignSchedule.popSettings.uniqueCreativesHelper',
                                {
                                    count: uniqueCreativeValues(field.value).length,
                                },
                            )}
                        />
                    </Stack>
                )}
            />

            <Controller
                name="audience_version_id"
                render={({ field }) => (
                    <AllEntitiesDropdown
                        {...field}
                        size="small"
                        entityName="Displays_AudienceVersion"
                        labelField="version_name"
                        includes={['source']}
                        additionalOptions={additionalOption}
                        label={t(
                            'Modules.Main.Campaigns.CampaignSchedule.popSettings.audienceVersionLabel',
                        )}
                        labelFormatter={(entity: Displays_AudienceVersion) =>
                            `${entity.version_name} (${(entity as any).source?.name || ''} - ${
                                formatDateToMonthYear((entity as any).date_last_revision) || ''
                            })`
                        }
                    />
                )}
            />
            <Stack>
                <Controller
                    name="energy_efficiency"
                    render={({ field }) => (
                        <Checkbox
                            {...field}
                            isBoolean
                            size="small"
                            label={t(
                                'Modules.Main.Campaigns.CampaignSchedule.popSettings.energyEfficiencyLabel',
                            )}
                        />
                    )}
                />
                <Controller
                    name="display_verification"
                    render={({ field }) => (
                        <Checkbox
                            {...field}
                            isBoolean
                            size="small"
                            label={t(
                                'Modules.Main.Campaigns.CampaignSchedule.popSettings.displayVerificationEfficiencyLabel',
                            )}
                        />
                    )}
                />
            </Stack>
            <Controller
                name="campaign_refresh_type"
                render={({ field }) => (
                    <SelectField
                        {...field}
                        size="small"
                        options={[
                            { label: ' Standard', value: 'standard' },
                            { label: ' Two Days Ago', value: 'two_days_ago' },
                            { label: ' Custom', value: 'custom' },
                        ]}
                        label={t(
                            'Modules.Main.Campaigns.CampaignSchedule.popSettings.refreshTypeLabel',
                        )}
                    />
                )}
            />
            <Controller
                name="campaign_refresh_type"
                render={({ field }) =>
                    field.value === 'custom' && (
                        <Controller
                            name="campaign_refresh_date"
                            render={({ field }) => (
                                <DateField
                                    {...field}
                                    inputProps={{ size: 'small' }}
                                    label={t(
                                        'Modules.Main.Campaigns.CampaignSchedule.popSettings.refreshDateLabel',
                                    )}
                                />
                            )}
                        />
                    )
                }
            />
            <PopSettingsLogSourceIdentifier />
        </AutoGrid>
    );
};
const PopSettingsFormWrapper: React.FC<Props> = ({ campaignId }) => {
    const { entity, pageConfig } = useEntityPage<Campaign>();
    const formOpts = useMemo(
        () => ({
            defaultValues: {
                audience_version_id: entity?.popSettings?.audience_source_id,
                creatives: entity?.popSettings?.creatives?.join('\n') ?? '',
                campaign_refresh_date:
                    entity?.popSettings?.campaign_properties?.refresh?.custom_date,
                campaign_refresh_type: entity?.popSettings?.campaign_properties?.refresh?.type,
                display_verification: entity?.display_verification_enabled,
                energy_efficiency: entity?.energy_efficiency_enabled,
                log_source_campaign_identifiers:
                    entity?.logSourceCampaignIdentifiers?.map(identifier => ({
                        source: identifier.log_source_account_id,
                        identifier: identifier.identifier,
                    })) ?? [],
            } as FormDataStructure,
        }),
        [entity],
    );

    const { start, requestStateRef, error, hasFailed, hasSucceeded, isLoading } =
        useAPIClientRequest(apiClient.Entities.Campaign.updatePopSettings);
    const { upsertEntity } = useEntityData('Campaign');
    const { value: successful, setTrue: setSuccessful } = useAutoResetBoolean(false, 2500);
    const onSubmit = useCallback(
        async (data: FormDataStructure) => {
            if (requestStateRef.current === NetworkRequestState.InProgress) return;

            let updatedCreatives = data.creatives;

            data.log_source_campaign_identifiers?.forEach(({ source, identifier }) => {
                if ([2, 4].includes(source)) {
                    const newCreativeName = `${identifier}_${campaignId}`;
                    if (!updatedCreatives.includes(newCreativeName)) {
                        updatedCreatives += `\n${newCreativeName}`;
                    }
                }
            });

            const [err, succ] = await to(
                start(
                    campaignId,
                    {
                        ...data,
                        creatives: uniqueCreativeValues(updatedCreatives),
                    } as SaveCampaignPopSettingsPayload,
                    pageConfig.systemSearch.defaultIncludes,
                ),
            );

            if (!err && succ?.data?.data?.id === campaignId) {
                upsertEntity(succ.data.data);
                setSuccessful();
            }
        },
        [campaignId, requestStateRef, start, upsertEntity, pageConfig, setSuccessful],
    );

    const t = useCommonTranslation();

    return (
        <FormWrapper<FormDataStructure> onSubmit={onSubmit} formOptions={formOpts}>
            <PopSettingsForm />
            <Stack gap={2}>
                {hasFailed && <Alert severity="error">{String(error)}</Alert>}
                {successful && (
                    <Alert severity="success">
                        {t('Modules.Main.Campaigns.CampaignSchedule.popSettings.saveSuccess')}
                    </Alert>
                )}
                <Button type="submit" disabled={isLoading}>
                    <Translate path="Modules.Main.Campaigns.CampaignSchedule.popSettings.submitBtn" />
                </Button>
            </Stack>
        </FormWrapper>
    );
};

export default PopSettingsFormWrapper;
